<template>
    <div id="age-gate">
        <Smallmodal
        :modalShow="showAgeGate"
        @closeSmallModal="closeModal()"
        >
        <div class="age-gate-container" v-if="usePIN == true">
            <div id="age-gate-heading">
                <h2>{{$t('agegate.heading')}}</h2>
            </div>
            <div v-if="error" class="error-msg">{{error}}</div>
            <br/>
            <input
            v-model.trim="agegate.firstnumber" 
            class="digits"
            type="password" 
            maxlength=1
            id="firstnumber" 
            name="firstnumber"
            required
            autofocus
            tabindex="1"    
            v-on:keyup.enter="$event.target.nextElementSibling.focus()"
            />
            <input
            v-model.trim="agegate.secondnumber" 
            class="digits"
            type="password" 
            maxlength=1
            id="secondnumber" 
            name="secondnumber"
            required
            autofocus
            tabindex="2"
            v-on:keyup.enter="$event.target.nextElementSibling.focus()"
            />
            <input
            v-model.trim="agegate.thirdnumber" 
            class="digits"
            type="password" 
            maxlength=1
            id="thirdnumber" 
            name="thirdnumber"
            required
            autofocus
            tabindex="3"
            v-on:keyup.enter="$event.target.nextElementSibling.focus()"
            /> 
            <input
            v-model.trim="agegate.forthnumber" 
            class="digits"
            type="password" 
            maxlength=1
            id="forthnumber" 
            name="forthnumber"
            required
            autofocus
            tabindex="4"
            v-on:keyup.enter="$event.target.nextElementSibling.focus()"
            />   

            <NumPad
            @setNum="numPad($event)"
            @clearNum="clearDigit"
            />
            <br/>          
            <b-button pill variant="primary" @click="validateForm()">
                <div class="confirm-icon ps-icons"></div>
                {{$t('global.confirm')}}
            </b-button>
            <br/><br/>  
            <div>
                <a href="#" @click.prevent="showPW()">{{$t('agegate.usepassword')}}</a>
            </div>
        </div>
        <div class="age-gate-container" v-if="usePW == true">
            <div id="age-gate-heading">
                <h2>{{$t('settings.reauthheading')}}</h2>
            </div>
            <div v-if="error" class="error-msg">{{error}}</div>
            <br/>
                <input
                v-model.trim="agegate.password"
                maxlength=50
                id="password"
                name="password"
                type="password"
                class="form-control"
                required
                />   
                <br/>
                <div class="link-text" @click="forgotPassword()">{{$t('auth.login.forgotpassword')}}</div>
                <br/>
                <div v-if="noPin == true">
                    <h3>You do not have a parental PIN, you can add one in settings.</h3>
                </div>
                <br/><br/>
                <div>
                <b-button pill variant="primary" @click="validatePWFrom()">
                    <div class="confirm-icon ps-icons d-inline-block"></div>
                    {{$t('global.confirm')}}
                </b-button>
                </div>
           
        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'
import NumPad from '@/components/numpad.vue'

export default {
    name: "Agegate", 
    components: {
        Smallmodal,
        NumPad
    },
    props:{
        showAgeGate: Boolean,
        nextAction: String
    },
    computed: {
        ...mapState(['userData']), 
    },
    watch: {
        'error':{
            handler (newVal){
                if(newVal != null){
                    this.tracking({
                        type: 'event',
                        category: 'pin',
                        action: 'pin:error'
                    });  
                }
            }
        },
        'showAgeGate':{
            handler (newVal){
                if(this.showAgeGate === true){
                    this.tracking({
                        type: 'page',
                        title: '/parentalpin',
                    });                      
                    this.hasPIN(); 
                }
            }
        }        
    },
    data: function() {
        return {
            error: null,
            usePIN: true,
            usePW: false,
            noPin: false,
            agegate: {
                firstnumber: null,
                secondnumber: null,
                thirdnumber: null,
                forthnumber: null,
                password: null
            }
        }   
    },
    methods: {
        closeModal() {
            this.$emit('closeAgeGate');
            this.resetPad();
        },
        validateForm() {
            if(this.agegate.firstnumber != null && this.agegate.secondnumber != null && this.agegate.thirdnumber != null && this.agegate.forthnumber != null) {
                this.checkPIN();               
            } else {
                this.error =  "invalid pin";
            }
        },
        validatePWFrom(){
            if(!this.agegate.password){
                this.error = this.$t('errors.passwordrequired');
                return;
            }
            this.checkPW();
        },
        async checkPW(){
            try {
                this.tracking({
                    type: 'event',
                    category: 'pin',
                    action: 'pin:pwconfirm'
                });              
                let result = await this.$store.dispatch('checkPassword', {
                    password: this.agegate.password
                });
                if(result.code != null){
                    this.error = result.message;
                } else {
                    //this.error = "good password";
                    this.resetPad();
                    this.$emit('correctPIN',this.nextAction);                    
                }
            }
            catch (error) {
                this.error = error.message;
                this.logError(error);
            }

        },
        showPW(){
            this.tracking({
                type: 'event',
                category: 'pin',
                action: 'pin:usepassword'
            });              
            this.usePW = true;
            this.usePIN = false;
        },
        checkPIN() {
            this.tracking({
                type: 'event',
                category: 'pin',
                action: 'pin:confirm'
            });            
            let PIN = this.agegate.firstnumber.toString() + this.agegate.secondnumber.toString() + this.agegate.thirdnumber.toString() + this.agegate.forthnumber.toString();
            if(this.userData.parentalPin === PIN){
                //this.error =  "Correct PIN";
                this.resetPad();
                this.$emit('correctPIN',this.nextAction);
            } else {
                this.error =  "Incorrect PIN";
            }
        },
        resetPad() {
            this.agegate.firstnumber = null;
            this.agegate.secondnumber = null;
            this.agegate.thirdnumber = null;
            this.agegate.forthnumber = null; 
            this.agegate.password = null; 
            this.error = null; 
            this.usePW = false;
            this.usePIN = true;         
        },
        clearDigit(){
            if(this.agegate.forthnumber != null) {
                this.agegate.forthnumber = null;
            } else if (this.agegate.thirdnumber != null) {
                this.agegate.thirdnumber = null;
            } else if (this.agegate.secondnumber != null) {
                this.agegate.secondnumber = null;
            } else if (this.agegate.firstnumber != null) {
                this.agegate.firstnumber = null;
            }
        },
        numPad(n) {
            if(this.agegate.firstnumber == null) {
                this.agegate.firstnumber = n;
            } else if (this.agegate.secondnumber == null) {
                this.agegate.secondnumber = n;
            } else if (this.agegate.thirdnumber == null) {
                this.agegate.thirdnumber = n;
            } else if (this.agegate.forthnumber == null) {
                this.agegate.forthnumber = n;
            }
        },
        hasPIN() {
            if(this.userData.parentalPin === null){
                this.noPin = true;
                this.showPW();
            }
        },
        forgotPassword() {
            this.tracking({
                type: 'event',
                category: 'pin',
                action: 'pin:forgot'
            });              
            this.$router.push('/forgot')
        },
    },
    mounted(){
        this.hasPIN();
    }
    
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#age-gate-heading {
    margin: 30px 0 0 0;
}
.age-gate-container {
    padding: 0 30px;
}
</style>