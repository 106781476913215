<template>
<div id="create-invite-container" v-if="showCreateInvite == true" v-on:click.self="closeInviteModal()">
    <div id="create-invite-modal">
        <div id="invitemodal-close">
            <a @click.prevent="closeInviteModal()">
                <img src="/img/btns/close.svg" width="22" height="22" alt="close" title="close"/>
            </a>
        </div>        
        <div id="create-invite-modal-step1" v-if="showCreateInviteStep1 == true">
            <form>
            <div class="text-center invite-aliens">
                <img src="/img/elements/invite/invite_aliens.svg" width="421" height="160"/>
                <div id="invite-alien-msg-1" class="alien-blurbs">{{$t('createinvite.invite-alien-msg-1')}}</div>
                <div id="invite-alien-msg-2" class="alien-blurbs">{{$t('createinvite.invite-alien-msg-2')}}</div>
            </div>
            <div>
                <h2>{{$t('createinvite.step1-heading')}}</h2>
                <div v-if="error" class="error-msg">{{error}}</div>
                <div v-else>
                    <p>{{$t('createinvite.step1-subheading')}}</p>
                </div>
                <br/>        
            </div>
            <div class="invite-form d-inline-block align-top">
            <b-row>
                <b-col>
                    <label>{{$t('createinvite.date')}} <span class="required">({{$t('global.required')}})</span></label>
                    <br/>
                    <b-form-datepicker
                    v-model.trim="inviteform.startdate"
                    type="date" 
                    id="startdate" 
                    name="startdate"
                    required
                    tabindex="1"
                    v-on:click.self="trackInput('startdate')"
                    :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                    >
                    </b-form-datepicker>                    
                </b-col>
                <b-col>
                    <label>{{$t('createinvite.starttime')}}</label>
                    <br/>

                    <div >
                    <b-form-timepicker
                    v-model.trim="inviteform.starttime"
                    type="time"
                    id="starttime" 
                    name="starttime"
                    class="form-control "
                    required
                    tabindex="2"
                    v-on:click.native="trackInput('starttime')"                    
                    /></div>        
                </b-col>
                <b-col>
                    <label>{{$t('createinvite.endtime')}}</label>
                    <br/>
  
                    <b-form-timepicker
                    v-model.trim="inviteform.endtime"
                    type="time" 
                    id="endtime" 
                    name="endtime"
                    class="form-control "
                    required
                    tabindex="3"
                    v-on:click.native="trackInput('endtime')"                 
                    />                                
                </b-col>
            </b-row>                
            <b-row>
                <b-col>
                    <label>{{$t('createinvite.guestname')}} <span class="required">({{$t('global.required')}})</span></label>
                    <input
                    v-model.trim="inviteform.guestname"
                    id="guestname"
                    name="guestname"
                    type="text"
                    class="form-control"
                    required
                    tabindex="5"
                    @click.self="trackInput('guestname')"
                    />   
                </b-col>
                <b-col>
                    <label>{{$t('createinvite.guestemail')}} <span class="required">({{$t('global.required')}})</span></label>
                    <input
                    v-model.trim="inviteform.guestemail"
                    id="guestemail"
                    name="guestemail"
                    type="text"
                    class="form-control"
                    tabindex="6"
                    @click.self="trackInput('guestemail')"
                    />             
                </b-col>
            </b-row>
            </div>
            <div class="d-inline-block">
                <div class="invite-info">
                    <div class="important-bubble d-inline-block">!</div>
                    <div class="blurb d-inline-block">
                        {{$t('createinvite.info-blurb')}}
                    </div>
                </div>
            </div>
            <br/><br/>  
                <b-button pill variant="primary" @click="valdiateForm()" v-if="saving == false">
                    <div class="submit-icon ps-icons"></div>
                    {{$t('global.submit')}}
                </b-button>
                <b-button pill variant="secondary" v-else>
                    <div class="loading-icon ps-icons"></div>
                    {{$t('global.saving')}}
                </b-button>                  
            </form>
        </div>
        <div id="create-invite-modal-step2" v-if="showCreateInviteStep2 == true">
            <b-row align-h="center" class="text-center invite-aliens">
                <b-col>
                    <img src="/img/elements/invite/confirmation_aliens.svg" width="450" height="204"/>
                    <div id="confrim-alien-msg-1" class="alien-blurbs">{{$t('createinvite.confrim-alien-msg-1')}}</div>
                    <div id="confrim-alien-msg-2" class="alien-blurbs">{{$t('createinvite.confrim-alien-msg-2')}}</div>
                </b-col>
            </b-row>
            <div v-if="error" class="error-msg">{{error}}</div>  
            <br/>
            <b-row>
                <b-col>                
                    <h2>{{$t('createinvite.step2-heading')}}</h2>
                    <p>{{$t('createinvite.step2-instructions')}}</p>
                </b-col>     
                <b-col>
                    <p>{{$t('createinvite.invitefrom')}}<span class="purple-text">{{this.activeProfile.name}}</span>{{$t('createinvite.emailedto')}}<span class="purple-text">{{this.inviteform.guestemail}}</span></p>
                    <br/>
                    <h3>{{this.$moment(this.fullstartdatetime).format('dddd, MMM Do, YYYY')}}</h3>
                    <h3>{{this.$moment(this.fullstartdatetime).format('hh:mm A')}} {{this.tzLookup(this.inviteform.timezone)}} {{$t('global.to')}} {{this.$moment(this.fullenddatetime).format('hh:mm A')}} {{this.tzLookup(this.inviteform.timezone)}}</h3> 
                    <p>{{$t('createinvite.guestlink')}}<br/>
                    <span class="purple-text"><a :href="this.inviteURL+this.inviteIdCode" @click="trackInviteLink()">{{this.inviteURL}}{{this.inviteIdCode}}</a></span>
                    </p>
                    <!--p>{{$t('createinvite.magiccode')}}<br/> 
                    <span class="purple-text">{{this.inviteform.passcode}}</span><br/></p-->    
                                  
                </b-col>                   
            </b-row>
            <br/><br/><br/>
            <b-row >
                <b-col class="btn-holder" >
                    <b-button pill variant="primary" v-if="copied == false" @click="copyToClipBoard()" >
                        <div class="copy-icon ps-icons"></div>
                        {{$t('global.copy')}}
                    </b-button>
                    <b-button pill variant="secondary" v-if="copied == true" @click="copyToClipBoard()">
                        <div class="copy-icon-purple ps-icons"></div>
                        {{$t('createinvite.copied')}}
                    </b-button>
                </b-col>
                <b-col >
                    <b-button pill variant="primary" id="send-email-btn" v-if="sending == false && sent == false" @click="emailSend()">
                        <div class="send-icon ps-icons"></div>
                        {{$t('global.sendemail')}}
                    </b-button>
                    <b-button pill variant="secondary" v-if="sending == true">
                        <div class="loading-icon ps-icons"></div>
                        Sending
                    </b-button>                        
                    <b-button pill variant="secondary" v-if="sent == true">
                        <div class="send-icon-purple ps-icons"></div>
                        {{$t('global.sent')}}
                    </b-button>                    
                </b-col>
            </b-row>    
        </div>
    </div>
</div>    
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    name: "Createinvite",
    computed: {
        ...mapState(['userData']),   
        ...mapState(['activeProfile']), 
        ...mapState(['currentRoom']), 
    },  
    props:{
        showCreateInvite: Boolean
    }, 
    watch: {
        'activeProfile': {
            handler (newVal) {
                if (newVal) { 
                    this.initCreateInviteForm();
                }
            },
            immediate: true 
        },
        'showCreateInvite': {
            handler (newVal){
                if(newVal === true){
                    this.tracking({
                        type: 'page',
                        title: '/invite/start',
                    });
                }
            }
        },
        'error':{
            handler (newVal){
                if(newVal != null){
                    this.tracking({
                        type: 'event',
                        category: 'invitation',
                        action: 'error'
                    });  
                }
            }
        }
    },     
    data() {
        return {
            inviteform: {
                startdate: null,
                starttime: null,
                endtime: null,
                //passcode: '',
                guestname: '',
                guestemail: '',
                timezone: null,
                profileId: null,
                roomId: null,
                status: 'scheduled'
            },
            error: null,
            type: null,
            copied: false,
            inviteURL: this.$store.getters.currentDomain + "/join/",
            inviteIdCode: null,
            fullstartdatetime: null,
            fullenddatetime: null,
            showCreateInviteStep1: true,
            showCreateInviteStep2: false,
            sent: false,
            sending: false,
            saving: false
        };
    },
    mounted (){
        //TODO lock endtime to 2 hours after start time.
        //TODO limit invitations to 8 a day? or some limit.
    },
    methods: {
        trackInviteLink() {
            this.tracking({
                type: 'event',
                category: 'invitation',
                action: 'playspacelink'
            });    
        },
        initCreateInviteForm() {
            this.saving = false;
            this.sent = false;
            this.error = null;
            let today = new Date();
            let momentdate = this.$moment(today).add(30, 'm');
            //sets profile info
            this.inviteform.profileId = this.activeProfile.id;
            this.inviteform.roomId = this.currentRoom.id;
            //this.inviteform.passcode = '';
            this.inviteform.guestname = '';
            this.inviteform.guestemail = '';
            //sets current date information.
            this.inviteform.startdate =  momentdate.format('YYYY-MM-DD');
            this.inviteform.starttime = momentdate.format('HH:mm');
            this.inviteform.endtime = momentdate.add(1, 'hours').format('HH:mm');
            this.sending = false;
            this.copied = false;
            this.showCreateInviteStep1 = true;
            this.showCreateInviteStep2 = false; 
            //TODO handle 'None' (not available)
            this.inviteform.timezone = this.$store.getters.currentTimezone;
        },
        valdiateForm(){
            //combined datetime data.
            this.error = null;
            this.saving = true;
            this.fullstartdatetime = this.$moment((this.inviteform.startdate + " " + this.inviteform.starttime));
            this.fullenddatetime = this.$moment(this.inviteform.startdate + " " + this.inviteform.endtime); 

            if(!this.inviteform.guestname){
                this.error = this.$t('errors.guestrequired');
                this.saving = false;
                return
            }
            if(this.fullstartdatetime){
                let today = new Date();
                let rightnow = this.$moment.utc(today);
                let playdatetime = this.$moment(this.fullstartdatetime);
                
                if(!playdatetime.isAfter(rightnow)){// rightnow
                    this.error = this.$t('errors.futuredate');
                    this.saving = false;
                    return;
                }
            }
            if(this.fullstartdatetime && this.fullenddatetime){
                if(!this.$moment(this.fullenddatetime).isAfter(this.fullstartdatetime)){
                    this.error = this.$t('errors.endmustbeafter');
                    this.saving = false;
                    return;                
                }
            }
            if(!this.inviteform.guestemail){
                this.error = this.$t('errors.guestemail');
                this.saving = false;
                return;
            } else {
                this.showEmailSend = true;
            }
            if(!this.validEmail(this.inviteform.guestemail)){
                this.error = this.$t('errors.validemail');
                this.saving = false;
                return;
            }             
            //if email is it valid
            this.submitInvite();
        },
        async submitInvite(){
            this.tracking({
                type: 'event',
                category: 'invitation',
                action: 'submit'
            });               
            let code = await this.$store.dispatch('addInvite', {
                startdate: this.inviteform.startdate,
                starttime: this.inviteform.starttime,
                endtime: this.inviteform.endtime,
                //passcode: this.inviteform.passcode,
                hostname: this.activeProfile.name,
                guestname: this.inviteform.guestname,
                guestemail: this.inviteform.guestemail,
                timezone: this.inviteform.timezone,
                profileId: this.inviteform.profileId,
                roomId: this.inviteform.roomId,
                status: this.inviteform.status
            })
            .then((response) => {
                let docRef = response;
                this.inviteIdCode = docRef;
                this.showCreateInviteStep1 = false;
                this.showCreateInviteStep2 = true; 
                this.tracking({
                    type: 'page',
                    title: '/invite/complete',
                }); 
            })
            .catch(error => {
                this.error = error.message;
                this.logError(error);
                //this.saving = false;
            }); 
        },
        async sendEmailInvite(){
            let api_url = process.env.VUE_APP_FIREBASE_FUNCTIONS+'send/invite/';
            let stime = this.$moment(this.fullstartdatetime).format('hh:mm A') + " " + this.tzLookup(this.inviteform.timezone); 
            let etime = this.$moment(this.fullenddatetime).format('hh:mm A') + " " + this.tzLookup(this.inviteform.timezone);
            let payload = {
                guestemail: this.inviteform.guestemail,
                guestname: this.inviteform.guestname,
                hostname: this.userData.firstname,
                hostemail: this.userData.email,
                hostprofilename: this.activeProfile.name,
                startdate: this.$moment(this.fullstartdatetime).format('dddd, MMM Do, YYYY'),
                starttime: stime,
                endtime: etime,
                //passcode: this.inviteform.passcode,
                linkcode: this.inviteURL + this.inviteIdCode
            };
            await axios.post(api_url, payload)
            .then(response => {
                return response;
            })      
            .catch(function(error) {
                return error.message;
            });                                
        },
        emailSend(){
            this.tracking({
                type: 'event',
                category: 'invitation',
                action: 'sendemail'
            });
                          
            this.sending = true; 
            
            this.sendEmailInvite()
            .then(() => {
                this.sending = false;
                this.sent = true;    
            })
            .catch(error => {
                this.error = error;
            }); 
        },
        copyToClipBoard() {
            this.tracking({
                type: 'event',
                category: 'invitation',
                action: 'copylink'
            });                
            this.copied = true;
            let text = this.$t('createinvite.invitefrom')+
            this.activeProfile.name+
            this.$t('createinvite.emailedto')+
            this.inviteform.guestemail + "\n  \n"+
            this.$moment(this.fullstartdatetime).format('dddd, MMM Do, YYYY') + "\n"+ 
            this.$moment(this.fullstartdatetime).format('hh:mm A')+" "+
            this.tzLookup(this.inviteform.timezone) +
            " "+this.$t('global.to') + " "+ this.$moment(this.fullenddatetime).format('hh:mm A') +" "+
            this.tzLookup(this.inviteform.timezone) + "\n \n" +
            this.$t('createinvite.guestlink')+ "\n"+ 
            this.inviteURL + this.inviteIdCode + "\n  \n";
            //"Magic code: \n"+
            //this.inviteform.passcode;

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        },
        closeInviteModal() {
            this.tracking({
                type: 'event',
                category: 'invitation',
                action: 'close'
            });             
            this.$emit('closeCreateInvite');
            // Resets data
            this.initCreateInviteForm();            
        },
        trackInput(label){
            this.tracking({
                type: 'event',
                category: 'invitation',
                action: 'invitation:forminput',
                label: label
            });     
        },        
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';
#endtime__value_, #starttime__value_, #startdate__value_ {
    border: none;
    box-shadow: none;
}
#endtime__dialog_, #starttime__dialog_, #starttime__dialog_ {
    z-index: 8600 !important;   
}
#starttime__outer_{
    z-index: 8504;
} 
#endtime__outer_{
    z-index: 8503;
}
#startdate__outer_ {
    z-index: 8505;
}
.b-calendar output, .b-time .b-time-header output {
    padding-top: 10px;
}
.b-form-btn-label-control.form-control {
    height: 40px;
    padding: 8px 0; 
}
.b-form-btn-label-control.form-control > .form-control {
    padding-top: 4px;
}
#create-invite-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(38, 32, 114, 0.5);
    z-index: 8000;
}
#invitemodal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 4100;
}
#create-invite-modal {
    position: relative;
    top: 52%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 860px;
    height: 460px;
    padding: 30px 30px 30px 60px;
    //padding: 30px 80px 30px 100px;
    background: $ps-light-purple;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    .invite-form {
        //max-width: 450px;
        width: 500px;             
    }
    .invite-info {
        padding: 30px 0 0 15px;
        width: 250px;
        .blurb {
            width: 200px;
            padding: 0 0 0 10px;
        }
    }
    .btn-holder {
        text-align: right; 
        .btn-primary {
                margin-right: 20px;
        }
    }
    .invite-aliens {
        margin: -95px 0 20px 0;
    }
    .alien-blurbs {
        font-size: $nav-size;
        line-height: $nav-lh;
    }
    #invite-alien-msg-1 {
        position: absolute;
        top: -18px;
        left: 295px;        
    }
    #invite-alien-msg-2 {
        position: absolute;
        top: -45px;
        right: 270px; 
    }
    #confrim-alien-msg-1 {
        position: absolute;
        top: 80px;
        left: 175px;
    }
    #confrim-alien-msg-2 {
        position: absolute;
        top: 38px;
        right: 174px;    
    }
}

</style>