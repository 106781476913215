<template>
    <div id="num-pad">
        <b-row>
            <b-col><div @click="numPad(1)" class="digit">1</div></b-col>
            <b-col><div @click="numPad(2)" class="digit">2</div></b-col>
            <b-col><div @click="numPad(3)" class="digit">3</div></b-col>
        </b-row>
        <b-row>
            <b-col><div @click="numPad(4)" class="digit">4</div></b-col>
            <b-col><div @click="numPad(5)" class="digit">5</div></b-col>
            <b-col><div @click="numPad(6)" class="digit">6</div></b-col>
        </b-row>
        <b-row>
            <b-col><div @click="numPad(7)" class="digit">7</div></b-col>
            <b-col><div @click="numPad(8)" class="digit">8</div></b-col>
            <b-col><div @click="numPad(9)" class="digit">9</div></b-col>
        </b-row>
        <b-row>
            <b-col><div class="digit">&nbsp;</div></b-col>
            <b-col><div @click="numPad(0)" class="digit">0</div></b-col>
            <b-col><div @click="clearDigit()" class="digit">&lt;</div></b-col>
        </b-row>                                        
    </div>
</template>

<script>

export default {
  name: "Numpad", 
  props: {

  },
  data() {
    return {

    };
  },
  methods: {
    numPad(n) {
      this.$emit('setNum',n);
    }, 
    clearDigit(){
        this.$emit('clearNum');
    }    
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#num-pad {
    padding: 30px 0;
    .digit {
        font-size: $h1-size;
        line-height: $h1-lh;
        cursor: pointer;
    }
}
</style>